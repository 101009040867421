<template>
	<div class="">
		<!-- 头部 -->
		<div class="header">
			<div class="header-time">
				<span class="time"></span>
				<div class=""></div>
				<span class="date"></span>
				<!-- <div class="header-btm"></div> -->
			</div>
			<div class="header-title"><span>河北交投实业开发有限公司曲阳石料厂智慧管理系统</span></div>
			<div :class="flag ? ' header-r header-index' : 'header-r header-all'">
				<span class="anquan">{{anquanday}}</span>
				安全生产（天）
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'headers',
	props: ['sign'],
	data() {
		return {
			flag: false,
			nowdate: '',
			nowtime: '',
			anquanday:0
		};
	},
	mounted() {
		let timer = setInterval(function() {
			var time = new Date();
			var year = time.getFullYear(); //获取年份
			var month = time.getMonth() + 1; //获取月份
			var day = time.getDate(); //获取日期
			var hour = checkTime(time.getHours()); //获取时
			var minite = checkTime(time.getMinutes()); //获取分
			var second = checkTime(time.getSeconds()); //获取秒
			/****当时、分、秒、小于10时，则添加0****/
			function checkTime(i) {
				if (i < 10) return '0' + i;
				return i;
			}
			this.nowdate = year + '年' + month + '月' + day + '日';
			this.nowtime = hour+":"+minite+":"+second
			var box_d = document.querySelector('.date')
			var box_t = document.querySelector('.time')
			box_d.innerHTML = this.nowdate 
			box_t.innerHTML = this.nowtime
		}, 1000);
		console.log(this.nowtime)
		if (this.$props.sign === 0) {
			this.flag = true;
		}
	
		this.getday()
	},
	
	methods:{
		async getday() {
			const res = await this.$postToken('datas/day')
			if(res.data.code==200){
				this.anquanday = res.data.result.day
			}
			}
	}
	
};
</script>

<style scoped>
.header {
	width: 100%;
	height: 90px;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 36px;
	box-sizing: border-box;
	margin-bottom: 30px;
}

.header .header-time {
	width: 507px;
	height: 60px;
	display: flex;
	align-items: center;
	/*   */
	background: url(../assets/images/header-l.png) no-repeat;
}

.header .header-time span:nth-child(1) {
	font-size: 32px;
	font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
	font-weight: 500;
	color: #ffffff;
	line-height: 38px;
}

.header .header-time span:nth-child(3) {
	font-size: 20px;
	font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
	font-weight: 400;
	color: #d0deee;
	line-height: 23px;
}

.header .header-time .fgx {
	height: 20px;
	width: 0px;
	opacity: 1;
	border: 1px solid;
	border-image: linear-gradient(270deg, rgba(108, 128, 151, 1), rgba(108, 128, 151, 0)) 1 1;
	margin: 0 1%;
}

.header-title {
	width: 836px;
	height: 90px;
	line-height: 90px;
	font-size: 32px;
	color: #ffffff;
	text-align: center;
	box-sizing: border-box;
	background: url(../assets/images/title.png) no-repeat;
}

.header-r {
	width: 507px;
	height: 60px;
	line-height: 60px;
	box-sizing: border-box;
	background: url(../assets/images/header-r.png) no-repeat;
	color: #d0deee;
}
.header-all {
	padding-left: 227px;
}
.header-index {
	padding-left: 163px;
}
.header-r span {
	font-size: 32px;
	padding-right: 3%;
	color: #ffffff;
}
.time{
	margin-left: 14px;
}
.date{
	font-size: 14px;
	margin-left: 50px;
}
</style>
