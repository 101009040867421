<template>
	<div class="box">
		<!-- header -->
		<headers></headers>
		<!-- 标题 -->
		<div class="power-top">
			<img src="../../assets/images/del.png" alt="" class="del" @click="back" />
			<div class="power-title">
				<img src="../../assets/images/t-l.png" alt="" />
				<span>生产线数据信息统计</span>
				<img src="../../assets/images/t-r.png" alt="" />
			</div>
		</div>
		<!-- 数量 -->
		<div class="materialsnum">
			<ul>
				<li>
					<div class="blue-sign"></div>
					<span>机制砂生产数量(累计)</span>
				</li>
				<li>
					<p>
						{{leijinum}}
						<span>T</span>
					</p>
				</li>
				<li>
					<el-date-picker type="daterange" v-model="dctime" @change="daochutime" value-format="yyyy-MM-dd"
						range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
					<div class="daochu" @click="exports">导出</div>
				</li>

			</ul>
		</div>
		<!-- 内容部分 -->
		<div class="p-con">
			<div class="p-con-item">
				<div class="p-con-title">
					<div class="p-con-title-bg"><span>生产线数据日统计</span></div>
					<el-date-picker @change="getrq1" value-format="yyyy-MM-dd" v-model="time1" type="date"
						placeholder="选择日期"></el-date-picker>
				</div>
				<!-- 今日入口材料数量 -->
				<div class="num-mx">
					<div class="num-mx-wz">
						<div class="num-sign"></div>
						<span>今日机制砂生产数量</span>
					</div>
					<p>
						{{Number(ridata.shengchan).toFixed(2)}}
						<span>T</span>
					</p>
				</div>
				<!-- 明细 -->
				<div class="p-list">
					<div class="p-list-title">
						<span>生成总量</span>
						<span>销售总量</span>
						<span>剩余库存</span>
					</div>
					<div class="p-list-con">
						<div>
							<p>{{Number(ridata.shengchan).toFixed(2)}}
								<span>T</span>
							</p>
							<p>
								{{Number(ridata.xiaoshou).toFixed(2)}}
								<span>T</span>
							</p>
							<p>
								{{Number(ridata.shengyu).toFixed(2)}}
								<span>T</span>
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="p-con-item">
				<div class="p-con-title ">
					<div class="p-con-title-bg"><span>生产线数据月统计</span></div>
					<el-date-picker @change="getrq2" value-format="yyyy-MM" v-model="time2" type="month"
						placeholder="选择日期"></el-date-picker>
				</div>
				<!-- 今日入口材料数量 -->
				<div class="num-mx">
					<div class="num-mx-wz">
						<div class="num-sign"></div>
						<span>本月机制砂生产数量</span>
					</div>
					<p>
						{{Number(yuedata.shengchan).toFixed(2)}}
						<span>T</span>
					</p>
				</div>
				<!-- 明细 -->
				<div class="p-list">
					<div class="p-list-title">
						<span>生成总量</span>
						<span>销售总量</span>
						<span>剩余库存</span>
					</div>
					<div class="p-list-con">
						<div>
							<p>{{Number(yuedata.shengchan).toFixed(2)}}
								<span>T</span>
							</p>
							<p>
								{{Number(yuedata.xiaoshou).toFixed(2)}}
								<span>T</span>
							</p>
							<p>
								{{Number(yuedata.shengyu).toFixed(2)}}
								<span>T</span>
							</p>
						</div>

					</div>
				</div>
			</div>
			<div class="p-con-item">
				<div class="p-con-title ">
					<div class="p-con-title-bg"><span>生产线数据年统计</span></div>
					<el-date-picker @change="getrq3" value-format="yyyy" v-model="time3" type="year"
						placeholder="选择日期"></el-date-picker>
				</div>
				<!-- 今日入口材料数量 -->
				<div class="num-mx">
					<div class="num-mx-wz">
						<div class="num-sign"></div>
						<span>今年机制砂生产数量</span>
					</div>
					<p>
						{{Number(niandata.shengchan).toFixed(2)}}
						<span>T</span>
					</p>
				</div>
				<!-- 明细 -->
				<div class="p-list">
					<div class="p-list-title">
						<span>生成总量</span>
						<span>销售总量</span>
						<span>剩余库存</span>
					</div>
					<div class="p-list-con">
						<div>
							<p>{{Number(niandata.shengchan).toFixed(2)}}
								<span>T</span>
							</p>
							<p>
								{{Number(niandata.xiaoshou).toFixed(2)}}
								<span>T</span>
							</p>
							<p>
								{{Number(niandata.shengyu).toFixed(2)}}
								<span>T</span>
							</p>
						</div>

					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import headers from '../../components/headers.vue';
	export default {
		components: {
			headers,
		},
		data() {
			return {
				time1: '',
				time2: '',
				time3: '',
				dctime: '',
				starttime: '',
				endtime: '',
				ridata: {
					shengchan: '0',
					shengyu: '0',
					xiaolv: '0',
					xiaoshou: '0',
				},
				yuedata: {
					shengchan: '0',
					shengyu: '0',
					xiaoshou: '0'
				},
				niandata: {
					shengchan: '0',
					shengyu: '0',
					xiaoshou: '0',
				},
				leijinum: "",
				type: 0 //0全部  1日 2月 3年
			};
		},
		mounted() {
			var date = new Date()
			var day = date.getDate()
			var month = date.getMonth() + 1
			var year = date.getFullYear()
			this.time3 = year.toString()

			if (parseInt(month) < 10) {
				month = '0' + String(month);
			}
			if (parseInt(day) < 10) {
				day = '0' + String(day);
			}

			this.time2 = year + '-' + month
			this.time1 = year + '-' + month + '-' + day
			this.getpidaidata()
			// let timer = setInterval(() => {
			// 	this.getpidaidata();
			// }, 30000);
		},
		methods: {
			// 返回上一级
			back() {
				this.$router.back();
			},
			async getpidaidata(a, b) {
				const res = await this.$postToken('datas/pidai-datas', {
					r_time: this.time1,
					y_time: this.time2,
					n_time: this.time3,
					type: this.type
				});
				if (res.data.code == 200) {
					this.ridata = res.data.result.ri
					this.yuedata = res.data.result.yue
					this.niandata = res.data.result.nian
					this.leijinum = res.data.result.leiji
				}
			},
			// 倒出时间区间
			daochutime() {
				if (this.dctime) {
					this.starttime = this.dctime[0]
					this.endtime = this.dctime[1]
				} else {
					this.starttime = ''
					this.endtime = ''
				}
			},
			// 导出
			async exports() {
				if (this.starttime == "" || this.endtime == "") {
					this.$message({
						message: '请选择导出时间范围',
						type: 'warning'
					})

				} else {
					const res = await this.$postToken('datas/pidai-daochu', {
						s_time: this.starttime,
						e_time: this.endtime,
					})
					// 
					console.log(res)
					if (res.data.code == 200) {
						window.location.href = res.data.result.url
					}
				}

			},
			//生产线日统计
			getrq1(e) {
				this.getpidaidata()
				console.log(this.time1)
			},
			// 生产线月统计
			getrq2() {
				this.getpidaidata()
				console.log(this.time2)
			},
			// 生产线年统计
			getrq3() {
				this.getpidaidata()
				console.log(this.time3)
			},
		}
	};
</script>
<style>
	.box {
		position: relative;
	}

	/* 标题 */
	.power-top {
		width: 100%;
		margin: 0 36px;
		position: relative;
		display: flex;
	}

	.del {
		display: block;
		left: 0;
	}

	.power-title {
		width: 400px;
		margin: 0 auto;
		text-align: center;
		color: #fff;
		font-size: 32px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.power-title img {
		display: block;
		width: 21px;
		height: 20px;
	}

	/* 数量，累计 */
	.materialsnum {
		width: 100%;
	}

	.materialsnum ul {
		width: 50%;
		margin: 46px auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.materialsnum ul li {
		display: flex;
		align-items: center;
	}

	.materialsnum ul li .blue-sign {
		width: 16px;
		height: 16px;
		background: #159aff;
		margin-right: 6px;
	}

	.materialsnum ul li .green-sign {
		width: 16px;
		height: 16px;
		background: #66e1df;
		margin-right: 6px;
	}

	.materialsnum ul li span {
		font-size: 24px;
		font-family: Alibaba PuHuiTi-Bold, Alibaba PuHuiTi;
		font-weight: bold;
		color: #d0deee;
	}

	.materialsnum ul li p {
		font-size: 34px;
		font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
		color: #ffffff;
	}

	.materialsnum ul li p span {
		font-weight: normal;
		font-size: 14px;
	}

	/* neirong  */
	.p-con {
		width: 100%;
		padding: 0 60px;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
	}

	.p-con-item {
		width: 30%;
	}

	.p-con-title {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		color: #fff;
	}

	.p-con-title-bg {
		width: 290px;
		height: 80px;
		background: url(../../assets/images/title\(1\).png) no-repeat;
		position: relative;
	}

	.p-con-title-bg span {
		position: absolute;
		top: -12px;
		left: 34px;
		font-size: 24px;
		font-weight: bold;
	}

	.el-input__inner,
	.el-range-input {
		background: #011633;
		border: 1px solid;
		color: #fff;
		border-image: linear-gradient(134deg, rgba(21, 154, 255, 1), rgba(102, 225, 223, 1)) 1 1;
	}

	.el-picker-panel__footer {
		background: #011633;
	}

	/*  */
	.num-mx {
		width: 100%;
		height: 64px;
		padding: 0 10px;
		box-sizing: border-box;
		background: rgba(102, 225, 223, 0.1);
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.num-mx .num-mx-wz {
		display: flex;
		align-items: center;
		font-size: 20px;
		color: #d0deee;
	}

	.num-mx .num-mx-wz .num-sign {
		width: 14px;
		height: 14px;
		background: #66e1df;
		margin-right: 4px;
	}

	.num-mx p {
		font-size: 34px;
		color: #ffffff;
	}

	.num-mx p span {
		font-size: 14px;
		color: #d0deee;
	}

	/*详情  */
	.p-list {
		width: 100%;
		height: 317px;
		background: rgba(102, 225, 223, 0.1);
		border: 1px solid #66e1df;
		box-sizing: border-box;
		margin-top: 20px;
	}

	.p-list-title {
		margin: 20px;
		background: rgba(102, 225, 223, 0.1);
		height: 56px;
		line-height: 56px;
		display: flex;
		align-items: center;
	}

	.p-list-title span {
		flex: 1;
		text-align: center;
		font-size: 20px;
		color: #d0deee;
	}

	.p-list-con div {
		display: flex;
		margin-bottom: 50px;
		align-items: center;
	}

	.p-list-con div p {
		flex: 1;
		text-align: center;
	}

	.p-list-con div .name {
		font-size: 18px;
		color: #d0deee;
	}

	.p-list-con div p {
		font-size: 30px;
		color: #ffffff;
	}

	.p-list-con div p span {
		font-size: 14px;
		color: #d0deee;
	}

	.el-date-picker__header-label,
	.el-picker-panel__icon-btn {
		color: #fff !important;
	}

	.el-picker-panel,
	.el-year-table a,
	.el-month-table a {
		background: #011633;
		color: #fff !important;
	}

	.el-range-input {
		color: #fff !important;
	}

	.daochu {
		background: #13374c;
		color: #fff;
		padding: 4px 10px;
		border-radius: 4px;
		margin-left: 20px;
		font-size: 16px;
	}
</style>